<script>
import axios from "@axios";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "TemplateEditFavicon",
  components: { ImageFieldForm },

  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      selectedFile: null,
      isLoading: false,
      uploadError: null,
      imageUrl: "",
      fileState: null,
    };
  },

  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.fileState = this.selectedFile ? true : null;
      this.uploadError = null;
      this.imageUrl = "";
    },

    async uploadFile() {
      if (!this.selectedFile) return;

      this.isLoading = true;
      this.uploadError = null;

      const formData = new FormData();
      formData.append("images", this.selectedFile);
      formData.append("path", "favicons");

      try {
        const response = await axios.post(
          `${this.APP_URL}/setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.imageUrl = response.data.files[0];
        const template = this.editTemplate;
        template.templateData.favicon = this.imageUrl;

        this.$store.commit(
          "app-whitelabel-templates/SET_EDIT_TEMPLATE",
          template
        );

        await this.$store
          .dispatch("app-whitelabel-templates/updateTemplate", {
            template: template,
            toast: this.$toast
          })
          .then(() => {});
      } catch (error) {
        this.uploadError = this.$t("error_uploading");
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    const favicon = this.editTemplate.templateData.favicon;
    if (favicon) {
      this.imageUrl = favicon;
    }
  },

  computed: {
    formatInvalid() {
      const { type } = this.selectedFile;
      return !(
        type.includes("png") ||
        type.includes("jpg") ||
        type.includes("jpeg")
      );
    },
    ...mapState("app-whitelabel-templates", ["editTemplate"]),
  },
};
</script>

<template>
  <div style="max-width: 300px; gap: 10px" class="d-flex flex-column px-1">
    <b-form-file
      v-model="selectedFile"
      accept="image/jpeg, image/jpg, image/png"
      @change="onFileChange"
      :state="fileState"
    ></b-form-file>

    <b-form-invalid-feedback v-if="selectedFile" :state="!formatInvalid">
      {{ $t("favicon_invalid_format") }}
    </b-form-invalid-feedback>

    <div>
      <b-button
        :disabled="!selectedFile || isLoading || formatInvalid"
        @click="uploadFile"
      >
        {{ $t("upload") }}
      </b-button>

      <b-spinner class="ml-1" small v-if="isLoading"></b-spinner>
    </div>

    <b-alert :show="!!uploadError" variant="danger">
      <h4 class="alert-heading">
        {{ $t("error_uploading") }}
      </h4>
    </b-alert>

    <img v-if="imageUrl" :src="imageUrl" alt="Uploaded Image" class="mt-3" />
  </div>
</template>

<style scoped lang="scss">
.mt-3 {
  margin-top: 1rem;
}
</style>
